import React from 'react';

import Link from '@ui/components/Link';
import { styled } from '@ui/styles';
import Box from '@ui/components/Box';

import ProductsMenu from './ProductsMenu';
import BrandCodeContext from '@web/components/BrandCodeContext';

const MenuButton = styled('a')(({ theme }) => ({
	color: theme.palette.text.primary,
	fontWeight: theme.typography.fontWeightBold,
	padding: theme.spacing(2),
	paddingRight: theme.spacing(2),
	marginLeft: theme.spacing(1.25),
	marginRight: theme.spacing(1.25),
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const KYClinicalMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
	const brandCode = React.useContext(BrandCodeContext);
	return (
		<Box
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			flexWrap="nowrap"
			pl={3}
			width="100%"
		>
			<Box
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				flexWrap="nowrap"
			>
				<ProductsMenu brandCode={brandCode} />
				{/* <Link href={`/mapa`} passHref legacyBehavior>
					<MenuButton>Onde encontrar</MenuButton>
				</Link> */}
			</Box>
		</Box>
	);
};

export default KYClinicalMenu;
