import { useCallback, useContext } from 'react';

import AppContext from '@common-lib/AppContext';
import processS3Path from '@common-lib/helpers/s3Image';

const useS3ImageUrl = (imageProxyUrl?: string) => {
	const { s3Proxy } = useContext(AppContext);
	const getS3Url = useCallback(
		(imageUrl: string, folder?: string) => {
			if (!imageUrl) {
				return imageUrl;
			}
			return processS3Path(imageUrl, imageProxyUrl || s3Proxy, folder);
		},
		[imageProxyUrl, s3Proxy],
	);
	return { getS3Url, s3Proxy };
};

export default useS3ImageUrl;
