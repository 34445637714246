import React from 'react';
import { AnimatedProps, animated, useSpring } from '@react-spring/web';

import { useTheme } from '@ui/styles/hooks';
import { styled } from '@ui/styles';
import { IconProps } from '@ui/icons/props';

const SVG = styled(animated.svg)(({ theme }) => ({
	marginLeft: theme.spacing(1),
	marginTop: theme.spacing(0.25),
}));
const DropArrow: React.FC<
	AnimatedProps<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> &
		Pick<IconProps, 'color' | 'htmlColor'> & { flip?: boolean }
> = ({ color, htmlColor, flip, ...rest }) => {
	const springs = useSpring({
		x: flip ? 1 : 0,
	});
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#9A4856';
	return (
		<SVG
			width="6"
			height="4"
			viewBox="0 0 6 4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
			style={{
				transform: springs.x
					.to([0, 1], [0, 180])
					.to(val => `rotate(${val}deg)`),
			}}
		>
			<path
				d="M2.98438 3.5L0.386299 0.5L5.58245 0.5L2.98438 3.5Z"
				fill={themeColor}
			/>
		</SVG>
	);
};

export default DropArrow;
