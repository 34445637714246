import React from 'react';
import { useQuery } from '@common-lib/graphql/hooks';

import Link from '@ui/components/Link';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@ui/components';
import { styled } from '@ui/styles';

import QUERY_PUBLIC from '@common-lib/modules/product/graphql/queryPublicDropdown.graphql';

import { BrandCode, IProduct, ListQueryResult } from '@interfaces/index';

import DropArrow from './DropArrow';
import Image from 'next/image';
import useS3ImageUrl from '@common-lib/hooks/useS3ImageUrl';

const MenuButton = styled('a')(({ theme }) => ({
	color: theme.palette.text.primary,
	fontWeight: theme.typography.fontWeightBold,
	padding: theme.spacing(2),
	paddingRight: theme.spacing(2),
	marginLeft: theme.spacing(1.25),
	marginRight: theme.spacing(1.25),
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const MenuLink = styled(ListItemText)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontWeight: theme.typography.fontWeightBold,
	cursor: 'pointer',
	width: '100%',
}));
const TheMenuItem = styled(MenuItem)<{ component: any }>(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	fontWeight: '700',
	fontSize: '0.875rem',
	lineHeight: '1rem',
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
}));
const ProductsMenu: React.FC<{
	brandCode?: BrandCode;
	renderIfNoProduct?: React.ReactNode;
}> = ({ brandCode, renderIfNoProduct }) => {
	const { getS3Url } = useS3ImageUrl();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = React.useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		},
		[],
	);
	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, []);
	const { data, ...rest } = useQuery<{
		public_products: ListQueryResult<IProduct>;
	}>(QUERY_PUBLIC, {
		variables: {
			brandCode: brandCode || null,
			pageSize: 100,
		},
	});
	const products = data?.public_products?.edges || [];
	if (!products.length) {
		return renderIfNoProduct || null;
	}
	return (
		<>
			<MenuButton onClick={handleClick}>
				Produtos
				<DropArrow
					color="primary"
					flip={Boolean(anchorEl)}
					width={9}
					height={6}
				/>
			</MenuButton>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							borderRadius: 16,
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							minWidth: 176,
							// '&:before': {
							// 	content: '""',
							// 	display: 'block',
							// 	position: 'absolute',
							// 	top: 0,
							// 	right: 14,
							// 	width: 10,
							// 	height: 10,
							// 	bgcolor: 'background.paper',
							// 	transform: 'translateY(-50%) rotate(45deg)',
							// 	zIndex: 0,
							// },
						},
					},
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
			>
				{products.map(m => (
					<Link
						key={m.node.slug}
						href={`/product/${m.node.slug}`}
						passHref
						legacyBehavior
					>
						<TheMenuItem component="a">
							<ListItemIcon>
								<Image
									src={getS3Url(m.node.mainImage || '')}
									alt={m.node.title}
									width={24}
									height={24}
								/>
							</ListItemIcon>
							<MenuLink>{m.node.title}</MenuLink>
						</TheMenuItem>
					</Link>
				))}
			</Menu>
		</>
	);
};

export default ProductsMenu;
